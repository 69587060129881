<template>
    <div class="modal" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Order Bumps</h1>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <ul class="bumps">
                    <li v-for="(bump, b) of orderBumps" :key="b">
                        <h4>{{ bump.name }}</h4>
                        <span class="view px-2 ml-auto" @click="handleEditBump(bump)"><i class="fas fa-edit"></i></span>
                        <span class="view px-2" @click="handleDeleteBump(bump)"><i class="fas fa-trash-alt text-danger"></i></span>
                    </li>
                    <li v-if="orderBumps.length == 0">No order bump found!</li>
                </ul>
                <div class="details_wpr" v-if="bumpEdit">
                    <Form v-slot="{ errors }" @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" class="cardItem_details">
                        <div class="header">
                            <button class="close_btn" type="button" @click="bumpEdit = false;"><i class="fas fa-chevron-down"></i></button>
                            <h2>Edit Order Bump</h2>
                        </div>
                        <div class="setting_wpr mb-5">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Order bump headline <a class="reset-default" @click="form.headline = defaultSettings.bump_headline">Reset to default</a></label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.product_bump_heading }">
                                        <Field autocomplete="off" type="text" name="product_bump_heading" v-model="form.headline" placeholder="ex: Order heading goeas here..." />
                                    </div>
                                    <ErrorMessage name="product_bump_heading" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Order bump description text <a class="reset-default" @click="form.description = defaultSettings.bump_description">Reset to default</a></label>
                                    <div class="field_wpr">
                                        <textarea v-model="form.description" placeholder="Description goes here.."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">This name will appear on your order summary</label>
                                    <div class="field_wpr has_prefix_lg" :class="{ 'has-error': errors.name }">
                                        <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="ex: Order Bump" label="product name" rules="required" />
                                        <span class="prefix_lg">Product Name</span>
                                    </div>
                                    <ErrorMessage name="name" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Price</label>
                                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors.price }">
                                        <Field autocomplete="off" :disabled="form.is_synced" type="number" name="price" min="0" v-model="form.price" placeholder="0" label="price" rules="required|min_value:0" />
                                        <span class="prefix">{{ companyCurrencySymbol }}</span>
                                    </div>
                                    <ErrorMessage name="price" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Select your Thrive Coach account</label>
                                    <div class="field_wpr">
                                        <multiselect
                                            v-model="bumpLocations"
                                            label="title"
                                            mode="tags"
                                            value-prop="id"
                                            :options="locations"
                                            :searchable="true"
                                            @select="handleBumpLocationSelect"
                                            @deselect="handleBumpLocationDeselect"
                                            placeholder="Select Locations"
                                        ></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp" v-for="(location, l) in bumpLocations" :key="l">
                                <div class="group_item">
                                    <label class="input_label">Order bump fulfillment tag ({{ tagsGroupByLocation[location].location_name }})</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors[`tag_bump_${location}`] }">
                                        <Field autocomplete="off" :name="`tag_bump_${location}`" v-model="bumpLocationsTags[l].tags" rules="required">
                                            <multiselect
                                                v-model="bumpLocationsTags[l].tags"
                                                mode="tags"
                                                label="name"
                                                value-prop="id"
                                                :searchable="true"
                                                :group-select="true"
                                                :options="tagsGroupByLocation[location].tags"
                                                :groups="true"
                                                group-label="type"
                                                group-options="tags"
                                                :group-hide-empty="true"
                                                placeholder="Select Tags"
                                            >
                                                <template v-slot:grouplabel="{ group }">
                                                    <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                </template>
                                                <template v-slot:option="{ option }">
                                                    <span>{{ option.name }}</span>
                                                </template>
                                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                    <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                      {{ option.name }}
                                                      <span
                                                        v-if="!disabled"
                                                        class="multiselect-tag-remove"
                                                        @mousedown.prevent="handleTagRemove(option, $event)"
                                                      >
                                                          <span class="multiselect-tag-remove-icon"></span>
                                                      </span>
                                                    </div>
                                                </template>
                                            </multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage :name="`tag_bump_${location}`" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <div class="action_wpr fixed_footer">
                            <button :disabled="bumpUpdateLoader" type="button" class="btn cancel_btn" @click="bumpEdit = false">Cancel</button>
                            <button :disabled="bumpUpdateLoader" class="btn save_btn">
                                <i class="fa fa-spin fa-spinner" v-if="bumpUpdateLoader"></i> {{ bumpUpdateLoader ? 'Saving' : 'Save' }}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Product Order Bump',

        data () {
            return {
                bumpEdit: false,
                bumpLocations: [],
                bumpLocationsTags: [],
                form: {},
            }
        },

        props: {
            modelValue: Number,
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                vm.bumpEdit = false;
                vm.getOrderBumps();
            },
        },

        computed: mapState({
            locations: state => state.checkoutModule.locations,
            orderBumps: state => state.checkoutModule.orderBumps,
            bumpUpdateLoader: state => state.checkoutModule.bumpUpdateLoader,
            checkoutAuthToken: state => state.checkoutModule.checkoutAuthToken,
            tagsGroupByLocation: state => state.checkoutModule.tagsGroupByLocation,
            companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
            defaultSettings: state => state.checkoutModule.defaultProductSettings,
        }),

        methods: {
            ...mapActions ({
                deleteBump: 'checkoutModule/deleteBump',
                updateBump: 'checkoutModule/updateBump',
                getOrderBumps: 'checkoutModule/getOrderBumps',
                getTagGroupByLocation: 'checkoutModule/getTagGroupByLocation',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', 0);
            },

            handleEditBump (bump) {
                const vm = this;
                const bumpLocations = [];

                vm.form = JSON.parse(JSON.stringify(bump));

                bump.locations.forEach((location) => {
                    if (!bumpLocations.includes(location.location_id)) {
                        bumpLocations.push(location.location_id);
                    }
                });

                vm.bumpLocations      = bumpLocations;
                vm.bumpLocationsTags  = JSON.parse(JSON.stringify(bump.locations));
                vm.bumpEdit           = true;
            },

            handleBumpLocationSelect (selectedOption, id) {
                const vm = this;

                vm.bumpLocationsTags.push({ location_id: selectedOption, tags: []})
            },

            handleBumpLocationDeselect (removedOption, id) {
                const vm = this;

                const index = vm.bumpLocationsTags.findIndex((row) => row.location_id == removedOption);

                if (index >= 0) {
                    vm.bumpLocationsTags.splice(index, 1);
                }
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.bump_locations_tags  = vm.bumpLocationsTags;
                params.setFieldError  = vm.setFieldError;

                vm.updateBump(params).then((result) => {
                    if (result) {
                        vm.getOrderBumps();
                        vm.bumpEdit = false;
                    }
                });
            },

            handleDeleteBump (bump) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this bump. Please type DELETE to remove this bump.`, 'Delete');

                options.confirmButtonColor = '#eb1414';
                options.input = 'text';
                options.inputAttributes = { placeholder: 'Enter DELETE' };
                options.inputValidator  = (input) => {
                                              if (/^[A-Z]/.test(input) == false) {
                                                  return 'Please enter all capital letters';
                                              }

                                              if (input !== 'DELETE') {
                                                  return `Please type DELETE to delete bump`;
                                              }

                                              Swal.enableButtons();
                                          }

                options.preConfirm = function () {
                                        return vm.deleteBump(bump.id).then((result) => {
                                            vm.getOrderBumps();
                                        });
                                    }

                options.willOpen        = function () {
                                              const button  = Swal.getConfirmButton();
                                              const input   = Swal.getInput()

                                              if (button) {
                                                  button.disabled = true;
                                              }

                                              input.oninput = (event) => {
                                                  const text = event.target.value;

                                                  if (/^[A-Z]/.test(text) == true && text === 'DELETE') {
                                                      Swal.enableButtons();
                                                  } else {
                                                      button.disabled = true;
                                                  }
                                              }
                                          }
                Swal.fire(options);
            },
        }
    }
</script>

<style scoped>
    .bumps li {
        padding: 15px;
        border: 1px solid #eee;
        background: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }

    .bumps li h4 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
    }

    .bumps li .view {
        padding: 0 5px 0 10px;
        font-size: 14px;
        display: flex;
        align-self: stretch;
        align-items: center;
        cursor: pointer;
        color: #5a5a5a;
    }

    .field_wpr.has_prefix_lg {
        padding-left: 130px;
    }

    .field_wpr.has_prefix_lg .prefix_lg {
        width: 130px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #e6e6e6;
        border-radius: 4px 0 0 4px;
        font-size: 14px;
        line-height: 17px;
        font-weight: 300;
        color: #949494;
        border-radius: 0;
    }

    .integration {
        padding: 10px 30px 20px 30px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
    }

    p.note {
        padding: 15px 20px;
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid rgba(47, 126, 237, 0.1);
        border-radius: 5px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #121525;
    }

    .section_header .settings {
        margin-left: 20px;
        cursor: pointer;
    }

    .section_header .settings i {
        font-size: 22px;
        color: #9f9e9e;
    }
</style>
